import request from '@/services/request.js'

export function ajaxCouponDetail(data) {
  return request({
    url: '/mall/V2/couponDetail',
    method: 'post',
    data,
    prefix: ''
  })
}

export function ajaxGetCoupon(data) {
  return request({
    url: '/mall/V2/getCoupon',
    method: 'post',
    data,
    prefix: ''
  })
}

export function ajaxShareCouponDetail(data) {
  return request({
    url: '/mall/V2/getShareCouponDetail',
    method: 'post',
    data,
    prefix: ''
  })
}

export function ajaxThreeCouponH5Share(data) {
  return request({
    url: '/share/threeCouponH5Share',
    method: 'post',
    data,
    prefix: ''
  })
}

export function ajaxNewActivityList(data) {
  return request({
    url: '/mall/V3/newActivityList',
    method: 'post',
    data,
    prefix: ''
  })
}

export function ajaxgetActivityCategoryInfo(data) {
  return request({
    url: '/mall/V3/getActivityCategoryInfo',
    method: 'post',
    data,
    prefix: ''
  })
}

export function ajaxGetCouponList(data) {
  return request({
    url: '/mall/V2/getCouponList',
    method: 'post',
    data,
    headers: {
      hideError: true
    },
    prefix: ''
  })
}
export function changeCode(data) {
  return request({
    url: 'app/nft/changeCode',
    method: 'post',
    data
  })
}
export function getChangeRecord(data) {
  return request({
    url: 'app/nft/getChangeRecord',
    method: 'post',
    data
  })
}
