<template>
  <div>
    <div class="couponDetail" :class="{ noCanGet: noCanGet }">
      <div>
        <div class="left">
          <div>{{ couponInfo.couponName }}</div>
          <div class="price">
            <span>￥</span><span>{{ couponInfo.amount }}</span><span>优惠券</span>
          </div>
          <div>有效期:{{ couponInfo.usedRemind }}</div>
          <img
            v-if="!noCanGet"
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuiy2LqLVhxxJ1601022447.png"
            alt=""
          >
          <img
            v-else
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuilekQr9PFpR1601022466.png"
            alt=""
          >
        </div>
        <div class="right" @click="handleGetCoupons">
          <div>立即领取</div>
        </div>
      </div>
    </div>
    <div class="goodsDetail">
      <div v-if="goodsInfo">
        <img :src="goodsInfo.goodsImg" alt="" class="left">
        <div class="right">
          <div class="title">{{ goodsInfo.goodsName }}</div>
          <div class="price">
            <span>￥</span><span>{{ goodsInfo.goodsCouponPrice }}</span><span>券后价</span>
          </div>
          <div>原价 ￥{{ goodsInfo.goodsVipPrice }}</div>
        </div>
        <!-- <div class="bottom" v-if="isandroid && wechat">
          <wx-open-launch-app
            id="launch-btn"
            appid="wxc662b914c8069214"
            extinfo=""
          >
            <template>
              <button class="btn" id="btn_sss" style="background:#f85966">查看商品</button>
            </template>
          </wx-open-launch-app>
        </div> -->
        <div class="bottom" @click="handleGoodsDetail">查看商品</div>
      </div>
    </div>

    <div class="explain">
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuide1IFeNHiy1601022516.png"
        alt=""
      >

      <div>
        <span v-for="item of remind" :key="item">{{ item }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import { ajaxCouponDetail, ajaxGetCoupon } from '../../services/couponPage'
import { isWeChat } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'

export default {
  name: 'CouponPage',
  data() {
    return {
      couponInfo: {},
      goodsInfo: '',
      remind: [],
      noCanGet: false,
      cid: '',
      parentId: '',
      codeNumber: '',
      wechat: false,
      isandroid: false,
      showLoading: true
    }
  },
  created() {
    this.cid = this.$route.query.cid
    this.parentId = this.$route.query.parentId || false
    this.codeNumber = this.$route.query.codeNumber
  },
  mounted() {
    this.$store.commit('changeInterceptUrl', window.location.href)
    this.$store.dispatch('loginIntercept', this.$route.query)
    this.getCouponData()
  },
  methods: {
    getPhoneType() {
      const that = this

      this.browser = {
        versions: (function() {
          var u = navigator.userAgent
          // var app = navigator.appVersion
          return {
            trident: u.indexOf('Trident') > -1, // IE内核
            presto: u.indexOf('Presto') > -1, // opera内核
            webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*!/), // 是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, // 是否iPad
            webApp: u.indexOf('Safari') === -1, // 是否web应该程序，没有头部与底部
            weixin: isWeChat(), // 是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) === ' qq' // 是否QQ
          }
        })(),
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase()
      }
      if (isWeChat()) {
        // 在微信中打开
        that.wechat = true
      }

      if (this.browser.versions.android) {
        // 是否在安卓浏览器打开
        that.isandroid = true
      }
    },
    getCouponData() {
      const that = this
      const data = {
        cid: this.cid,
        couponActivityId: 1,
        memberId: localStorage.getItem('uid'),
        uid: this.parentId
      }

      ajaxCouponDetail(data).then((res) => {
        if (Number(res.code) === 200) {
          that.couponInfo = res.data.couponInfo
          that.goodsInfo = res.data.goodsInfo
          that.remind = res.data.remind

          that.share()
          if (res.data.state === 1) {
            that.noCanGet = true
          }
        } else {
          Toast(res.msg)
          that.noCanGet = true
        }
      })
    },
    handleGetCoupons() {
      const data = {
        uid: this.parentId, // 分享者的id
        cid: this.cid, // 优惠券的ID
        memberId: localStorage.getItem('uid'), // 领取者的ID
        room_id: this.$route.query.room_id || 0
      }
      ajaxGetCoupon(data).then((res) => {
        if (Number(res.code) === 200) {
          Toast('领取成功')
        } else {
          Toast(res.msg)
          this.noCanGet = true
        }
      })
    },
    share() {
      const that = this
      const shareData = {
        // 配置直播中的分享信息
        title: that.goodsInfo.goodsName,
        desc: '劵后价：' + that.goodsInfo.goodsCouponPrice,
        link: window.location.href,
        imgUrl: that.goodsInfo.goodsImg
      }
      wxConfigInit(shareData)
      // this.$store.dispatch('wxConfigInit', shareData)
    },
    handleGoodsDetail() {
      if (this.cid === '2493') {
        this.goApp()
      } else {
        window.location.href =
          'https://single.yuetao.group/good-detail.html?product_id=' +
          this.goodsInfo.goodsId +
          '&product_sku_id=' +
          this.goodsInfo.skuId +
          '&type=ordinary' +
          '&productType=' +
          this.getQueryVariable('productType') +
          '&room_id=' +
          (this.getQueryVariable('room_id')
            ? this.getQueryVariable('room_id')
            : 0)
      }
    },
    goApp() {
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        var loadDateTime = new Date()
        // var state = window.open(
        //   'https://www.yuelvhui.com/ytApp/openPage?redirectType=3&' +
        //   'id=' +
        //   this.goodsInfo.goodsId +
        //   '&skuId=' +
        //   this.goodsInfo.skuId
        // )
        window.setTimeout(function() {
          var timeOutDateTime = new Date()
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location =
              'https://itunes.apple.com/cn/app/%E6%82%A6%E6%97%85%E4%BC%9A/id1460325408?mt=8'
          } else {
            window.close()
          }
        }, 25)
        window.location =
          'https://www.yuelvhui.com/ytApp/openPage?redirectType=3&' +
          'id=' +
          this.goodsInfo.goodsId +
          '&skuId=' +
          this.goodsInfo.skuId
      } else if (navigator.userAgent.match(/android/i)) {
        // var loadDateTime = new Date()
        // var state = window.open(
        //   'https://www.yuelvhui.com/ytApp/openPage?redirectType=3&' +
        //   'id=' +
        //   this.goodsInfo.goodsId +
        //   '&skuId=' +
        //   this.goodsInfo.skuId
        // )
        window.setTimeout(function() {
          var timeOutDateTime = new Date()
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location =
              'https://a.app.qq.com/o/simple.jsp?pkgname=com.newHuanQiuYueLv.www'
          } else {
            window.close()
          }
        }, 25)
      }
    }
  }
}
</script>
<style lang="less" scoped>
body {
  min-height: 100%;
  background: #f6f5f8;
}

.couponDetail {
  width: 100%;
  height: 4.04rem;
  background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhui0Pi6MCcpbE1601022332.png");
  background-size: 100% 100%;
  padding-top: 0.5rem;
  box-sizing: border-box;
  position: relative;
}

.couponDetail > div {
  height: 3rem;
  margin: 0 5%;
  border-radius: 0.18rem;
  display: flex;
  background: #fff;
}

.couponDetail .left {
  width: 5.694rem;
  height: 100%;
  background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuiZtwt2pCacS1601022349.png");
  box-sizing: border-box;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.22rem;
  color: #ffbec7;
  position: relative;
}

.couponDetail .left > div:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.28rem;
  width: 100%;
  color: #fff;
  padding: 0 0.2rem;
  box-sizing: border-box;
  text-align: center;
}

.couponDetail .left .price {
  color: #fff;
  font-size: 0.3rem;
}

.couponDetail .left .price span:nth-child(1) {
  font-size: 0.4rem;
}

.couponDetail .left .price span:nth-child(2) {
  font-size: 0.88rem;
  padding-right: 0.13rem;
}

.couponDetail .left img {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.43rem;
  height: 1.144rem;
}

.couponDetail .right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 0 0.18rem 0.18rem 0;
}

.couponDetail .right > div {
  width: 1.7rem;
  height: 0.88rem;
  background: linear-gradient(90deg,
  rgba(234, 64, 90, 1),
  rgba(248, 108, 99, 1));
  border-radius: 0.34rem;
  color: #fff;
  font-size: 0.22rem;
  text-align: center;
  line-height: 0.88rem;
}

.noCanGet .left {
  line-height: 1.5;
  color: #666666;
  background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuinmiNe2Euwb1601022382.png");
}

.noCanGet .left > div:nth-child(1) {
  color: #141414;
}

.noCanGet .left .price span {
  color: #000000;
}

.noCanGet .right > div {
  background: #dadada;
  color: #000000;
}

.goodsDetail {
  width: 100%;
  height: 5.2rem;
  background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-09-25/16/yuelvhuiYAgbUIwLAE1601022366.png");
  background-size: 100% 100%;
  padding-top: 0.48rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: -0.13rem;
}

.goodsDetail > img {
  width: 9.126rem;
  height: 4.056rem;
  margin: 0 auto;
  display: block;
}

.goodsDetail > div {
  width: 9.126rem;
  height: 4.056rem;
  margin: 0 auto;
  padding: 0.3rem 0.26rem;
  box-sizing: border-box;
  position: relative;
  background: #f8f6f9;
  display: flex;
  border-radius: 0.23rem;
}

.goodsDetail .left {
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.18rem;
  display: block;
  margin-right: 0.2rem;
}

.goodsDetail .right {
  flex: 1;
  font-size: 0.24rem;
  color: #999999;
  text-align: left;
}

.goodsDetail .right .title {
  min-height: 0.76rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #333333;
  font-size: 0.28rem;
  padding-top: 0.13rem;
  overflow: hidden;
  line-height: 1.5;
}

.goodsDetail .right .price {
  color: #cb2519;
  font-size: 0.26rem;
}

.goodsDetail .right .price span:nth-child(1) {
  font-size: 0.3rem;
}

.goodsDetail .right .price span:nth-child(2) {
  font-size: 0.52rem;
  padding-right: 0.08rem;
}

.goodsDetail .right > div:last-child {
  text-decoration: line-through;
}

.goodsDetail .bottom {
  position: absolute;
  width: 100%;
  height: 0.92rem;
  background: #f85966;
  left: 0;
  bottom: 0;
  border-radius: 0 0 0.18rem 0.18rem;
  text-align: center;
  line-height: 0.92rem;
  color: #fff;
  font-size: 0.3rem;
}

.explain {
  width: 9.126rem;
  height: auto;
  margin: 0.3rem auto;
  background: #fff;
  text-align: left;
  padding: 0.31rem 0.18rem;
  box-sizing: border-box;
  border-radius: 0.18rem;
}

.explain > img {
  width: 20%;
  margin: 0 auto;
  margin-bottom: 0.34rem;
  display: block;
}

.explain > div {
  color: #666666;
  font-size: 0.26rem;
}

.explain > div span {
  display: block;
  line-height: 0.5rem;
  padding-top: 0.18rem;
}

/*toast*/
.toast {
  position: fixed;
  top: 40%;
  left: 0.375rem;
  padding: 0.2rem 0.3rem;
  background: rgba(0, 0, 0, 0.6);
  width: 6rem;
  color: #fff;
  text-align: center;
  font-size: 0.28rem;
  -webkit-border-radius: 0.08rem;
  -moz-border-radius: 0.08rem;
  border-radius: 0.08rem;
  z-index: 100;
}

.toast {
  display: none;
}
</style>
